.formContainer {
    display: flex;
    flex-wrap: wrap; /* Enable wrapping of items */
    justify-content: flex-start; /* Align items to the start */
    gap: 20px; /* Gap between forms */
    margin-top: 50px;
}

.addUserForm {
    border: 2px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    flex-basis: calc(50% - 20px); /* Set basis for two-column layout, adjusting for gap */
    min-height: 300px; /* Optional: Set a minimum height */
    box-sizing: border-box; /* Include padding and border in the width calculation */
}

#spinner{
    margin-left: 20px;
}
.saveButton{
    margin-top: 20px;
}

#welcomeContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#viewContainer {
    padding: 2vw;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

/* Style for table header */
th {
    background-color: #f2f2f2;
    padding: 8px;
    text-align: left;
}

/* Style for table body */
td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

/* Style for alternating row colors */
tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* Style for table heading */
h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

#usersContainer {
    margin-top: 40px; /* Add vertical spacing */
}

/* Adjust styles for individual tables */
.zoomUsersTable,
.allUsersTable {
    width: 100%; /* Set width to 100% for both tables initially */
    margin-bottom: 40px; /* Add spacing between tables and elements below */
}

/* Media query for screens larger than 768px */
@media (min-width: 768px) {
    .zoomUsersTable,
    .allUsersTable {
        margin-bottom: 0; /* Reset margin for larger screens */
    }
}