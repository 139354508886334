#calendarContainer {
    padding: 2vw;
}

.form-label {
    margin: 6px
}

.form-date-picker {
    margin: 6px;
}
.form-text-box {
    margin: 6px;
}