
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
body {
    font-family: "Open Sans", sans-serif;
    margin: 0;
}

.site-header {
    border-bottom: 0.1vw solid #ccc;
    padding-left: 1vw;
    padding-right: 1vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.site-identity h4 {
    padding: 1vw;
    display: inline-block;
}
.site-navigation ul,
.site-navigation li {
    margin: 0;
    padding: 0;
}
.site-navigation li {
    display: inline-block;
    margin: 1vw;
}
.hfn-icon {
    width: 30px;
    height: 30px;
}


button {
    font-weight: 100;
    text-decoration: none;
    color: #000;
    all:unset;
    font-size: 0.9vw;
}
button:hover {
    color: rgb(179, 179, 179);
}