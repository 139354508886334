#spinner{
    align-self: center;
}
#calendarContainer{
    display: flex;
    flex-direction: column;
}
#refreshButton {
    width: 15px;
    height: 25px;
}

#welcomeContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.url-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
    word-break: break-all;
  }
  
  .url-container > div {
    margin-bottom: 0.5rem;
  }

  .email-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .email-item span {
    margin-right: 0.5rem;
  }

.form-duration-input {
    margin: 10px; /* Or any other spacing you prefer */
}
.custom-text {
  font-size: 16px;
  font-weight: bold;
  color: #333; /* Dark gray color */
}